var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"id":"home-app-bar","sticky":"","color":"secondary","elevation":"5","height":"80"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":_vm.$vuetify.breakpoint.smAndDown ? 'space-between' : 'space-between'}},[_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('router-link',{attrs:{"to":_vm.tableId && _vm.$route.name !== 'ThankYou' ? "/menu" : ''}},[_c('base-img',{staticClass:"logo mb-1",staticStyle:{"max-width":"170px"},attrs:{"src":_vm.getLogo,"contain":""}})],1)],1),(
            _vm.tableId &&
            _vm.$route.name !== 'ShoppingCart' &&
            _vm.$route.name !== 'Welcome' &&
            _vm.$route.name !== 'ThankYou' &&
            _vm.$route.name !== 'Bookings' &&
            _vm.$route.name !== 'Bookings-Thanks' &&
            _vm.checkCallWaiter === true
          )?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"small":"","text":"","elevation":"0","height":"48px"},on:{"click":_vm.callWaiter}},[_vm._v(" "+_vm._s(_vm.$t("callWaiter"))+" ")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-row',{staticClass:"container__menu justify-end align-center"},[_c('UserMenu')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }